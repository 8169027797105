import React from "react";
import { Menu, Dropdown, Button, Image } from "antd";
import { useTranslation } from "react-i18next";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { FcAndroidOs } from "react-icons/fc";
import { FaAppStore } from "react-icons/fa";
import {  IOSSeller,IOSCustomer,AndroidSeller,AndroidCustomer } from "../../assets";
import { useNavigate } from "react-router-dom";
import style from "./style.module.css";

const { SubMenu } = Menu;

const DownloadAppDropDown = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const menu = (
    <Menu>
      <SubMenu title={t("Seller App")} className={style.Download_App}>
        <SubMenu
          title={
            <span>
              <FcAndroidOs /> {t("Android")}
            </span>
          }
        >
          <Menu.Item>
            <div className={style.menu_content}>
              <Image
                className={style.menu_content_img}
                src={AndroidSeller}
                alt="apk_seller"
              />
              <Button
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.sellermobapp",
                    "_blank"
                  );
                }}
                key="apk_seller"
              >
                {t("Download from store")}
              </Button>
            </div>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          title={
            <span>
              <FaAppStore /> {t("IOS")}
            </span>
          }
        >
          <Menu.Item>
            <div className={style.menu_content}>
              <Image
                className={style.menu_content_img}
                src={IOSSeller}
                alt="IOS Option 1"
              />
              <Button
               onClick={() => {
                window.open(
                  "https://apps.apple.com/eg/app/sona3-seller/id6547854628",
                  "_blank"
                );
              }}
                key="ios_customer_1"
              >
                {t("Download from store")}
              </Button>
            </div>
          </Menu.Item>
        </SubMenu>
      </SubMenu>
      <SubMenu title={t("Customer App")} className={style.Download_App}>
        <SubMenu
          title={
            <span>
              <FcAndroidOs /> {t("Android")}
            </span>
          }
        >
          <Menu.Item>
            <div className={style.menu_content}>
              <Image
                className={style.menu_content_img}
                src={AndroidCustomer}
                alt="Android Option 1"
              />
              <Button
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.sona3customerapp",
                    "_blank"
                  );
                }}
                key="ios_customer_1"
              >
                {t("Download from store")}
              </Button>
            </div>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          title={
            <span>
              <FaAppStore /> {t("IOS")}
            </span>
          }
        >
          <Menu.Item>
            <div className={style.menu_content}>
              <Image
                className={style.menu_content_img}
                src={IOSCustomer}
                alt="iOS Option 1"
              />
              <Button
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/eg/app/sona3/id6736483587",
                    "_blank"
                  );
                }}
                key="ios_customer_1"
              >
                {t("Download from store")}
              </Button>
            </div>
          </Menu.Item>
        </SubMenu>
      </SubMenu>
    </Menu>
  );

  return (
    <nav>
      <Dropdown overlay={menu}>
        <Button
          id={style.Download_BTN}
          style={{
            direction: i18n.language === "en" ? "rtl" : "ltr",
            fontFamily: i18n.language === "en" ? "GE_SS_bold" : "GE_SS_light",
          }}
        >
          <label className={style.label}>{t("Download App")}</label>
          <HiOutlineDevicePhoneMobile className={style.phone_icon} />
        </Button>
      </Dropdown>
    </nav>
  );
};

export default DownloadAppDropDown;
