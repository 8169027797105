import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import LangDropDown from '../Lang/LangDropDown';
import DownloadAppDropDown from '../DownloadAppDropDown';
import { logo,editedLogo } from '../../assets';
import style from './styles/nav.module.css';
import { SELLER_PORTAL, SHOP_PORTAL } from '../../env';

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const indicatorRef = useRef(null);

  const activeClassName = (pathname) => 
    location.pathname === pathname ? style.Navbar_li_active : style.Navbar_li;

  const { t, i18n } = useTranslation();
  useEffect(() => {
    const handleIndicator = (el) => {
      indicatorRef.current.style.width = `${el.offsetWidth}px`;
      indicatorRef.current.style.left = `${el.offsetLeft}px`;
      indicatorRef.current.style.opacity = '1';
    };
  
    const hideIndicator = () => {
      indicatorRef.current.style.opacity = '0';
    };
  
    const items = document.querySelectorAll(`.${style.Navbar_li}, .${style.Navbar_li_active}`);
  
    items.forEach((item) => {
      item.addEventListener('mousemove', (e) => handleIndicator(e.target));
      item.addEventListener('mouseleave', hideIndicator);
    });
  
    return () => {
      items.forEach((item) => {
        item.removeEventListener('mousemove', (e) => handleIndicator(e.target));
        item.removeEventListener('mouseleave', hideIndicator);
      });
    };
  }, []);
  
  return (
    <div className={style.Navbar}>
        <div className={style.wrapping_content}>
          <div className={style.Image_Container}>
              <img onClick={()=>{navigate("/")}} src={editedLogo} className={style.Navbar_logo} alt='logo'/>
          </div>
          <div className={style.Routes_Container}>
            <NavLink to="/"  className={activeClassName("/")}>{t("Home")}</NavLink>
            <NavLink to="/about" className={activeClassName("/about")} >{t("About")}</NavLink>
            <NavLink to="/be%20a%20partner" className={activeClassName("/be%20a%20partner")} >{t("Be a partner")}</NavLink>
            <NavLink to="/sustainability" className={activeClassName("/sustainability")} >{t("Sustainability")}</NavLink>
            {/* <NavLink to="/services" className={activeClassName("/services")} >{t("Services")}</NavLink> */}
            <NavLink to={i18n?.language === 'en' ?"https://sona3.blog/" :"https://sona3.blog/ar"} className={activeClassName("/blog")} >{t("Blog")}</NavLink>
            <NavLink to="/support" className={activeClassName("/support")} >{t("Support")}</NavLink>
            <div className={style.indicator} ref={indicatorRef}></div>
          </div>
        </div>
        <div className={style.Control_Container}>
          <DownloadAppDropDown/>
          <Button style={{fontFamily: i18n.language === 'en' ? 'JosefinSans' : 'GE_SS_light',}} onClick={()=>{  window.open(SHOP_PORTAL, '_blank')}} id={style.ShopNow_Btn}>{t("Shop")}</Button>
          <Button style={{fontFamily: i18n.language === 'en' ? 'JosefinSans' : 'GE_SS_light',}} onClick={()=>{ window.open(`${SELLER_PORTAL}/?lang=${i18n?.language}`, '_blank')}} id={style.Register_Btn}>{t("Register Now")}</Button>
          <LangDropDown/>
        </div>
    </div>
  );
}
