export const twitter_solid = require('./imgs/twitter_solid.svg').default;
export const facebook_solid = require('./imgs/facebook_solid.svg').default;
export const instagram_solid = require('./imgs/instagram_solid.svg').default;
export const linkedin_solid = require('./imgs/linkedin_solid.svg').default;
export const snap_solid = require('./imgs/snap_solid.svg').default;
export const tiktok_solid = require('./imgs/tik_solid.svg').default;
export const footer_instagram = require('./imgs/footer_instagram.svg').default;








export const facebook_solid_png = require('./imgs/facebook_solid.png');
export const app_store = require('./imgs/app_store.png');
export const google_store = require('./imgs/google_store.png');
export const post_img = require('./imgs/post_img.png');
export const sona3 = require('./imgs/sona3.png');
export const twitter = require('./imgs/twitter.png');
export const snap = require('./imgs/snap.png');
export const tiktok = require('./imgs/tiktok_footer.png');
export const licence = require('./imgs/licence.jpg');


// export const emptyServiceRequests = require('./imgs/emptySR.png');
export const logo = require('./imgs/logo.png');
export const editedLogo = require('./imgs/logo 1.png');
export const ar = require('./imgs/ar.png');
export const arrow = require('./imgs/arrow.png');
export const bag = require('./imgs/bag.png');
export const car = require('./imgs/car.png');
export const catalog = require('./imgs/catalog.png');
export const cover = require('./imgs/cover.png');
export const coverf = require('./imgs/coverf.png');
export const cs = require('./imgs/cs.png');
export const en = require('./imgs/en.png');
export const facebook = require('./imgs/facebook.png');
export const gps_solid = require('./imgs/gps_solid.png');
export const group281 = require('./imgs/Group 281.png');
export const h_line = require('./imgs/h_line.png');
export const icon_1 = require('./imgs/piece of art.png');
export const icon_3 = require('./imgs/services.png');
export const home = require('./imgs/home.png');
export const medal = require('./imgs/details and quality.png');
export const icon_2 = require('./imgs/range of product.png');
export const image = require('./imgs/image.png');
export const inImg = require('./imgs/in.png');
export const instagram = require('./imgs/instagram.png');
export const instagram_solid_png = require('./imgs/instagram_solid.png');
export const layla = require('./imgs/layla.png');
export const linkedin_footer = require('./imgs/linkedin_footer.png');
export const mail_solid = require('./imgs/mail_solid.png');
export const main = require('./imgs/main.png');
export const note = require('./imgs/note.png');
export const pay = require('./imgs/pay.png');
export const person = require('./imgs/person.png');
export const persons = require('./imgs/persons.png');
export const persons_old = require('./imgs/persons_old.png');
export const phone_solid = require('./imgs/phone_solid.png');
export const pintrest_solid = require('./imgs/pintrest_solid.png');
export const popular = require('./imgs/popular.png');
export const sand = require('./imgs/sand.png');
export const sarah = require('./imgs/sarah.png');
export const sec1 = require('./imgs/sec1.png');
export const sec2 = require('./imgs/sec2.png');
export const sec3 = require('./imgs/sec3.png');
export const snap_png = require('./imgs/snap.png');
export const snap_2 = require('./imgs/snap_2.png');
export const stcs = require('./imgs/stcs.png');
export const s_line = require('./imgs/s_line.png');
export const target = require('./imgs/target.png');
export const tiktok_png = require('./imgs/tiktok.png');
export const tiktok_footer = require('./imgs/tiktok_footer.png');
export const twitter_png = require('./imgs/twitter.png');
export const twitter_svg = require('./imgs/twitter.svg');
export const twitter_solid_png = require('./imgs/twitter_solid.png');
export const vector = require('./imgs/vector.png');
export const v_line = require('./imgs/v_line.png');
export const whatsapp1 = require('./imgs/WhatsApp-Image-2023-10-18-at-5.06.webp');
export const whatsapp2 = require('./imgs/WhatsApp-Image-2023-10-18-at-5.07.webp');
export const whatsapp_svg = require('./imgs/whatsapp.svg');

export const b1 = require('./blog/1.jpg')
export const b2 = require('./blog/2.jpg')
export const b3 = require('./blog/3.jpg')
export const b4 = require('./blog/4.jpg')
export const b5 = require('./blog/5.jpg')
export const b6 = require('./blog/6.jpg')
export const b7 = require('./blog/7.jpg')
export const b8 = require('./blog/8.jpg')
export const b9 = require('./blog/9.jpg')
export const b10 = require('./blog/10.jpg')
export const b11 = require('./blog/11.jpg')
export const b12 = require('./blog/12.jpg')
export const b13 = require('./blog/13.jpg')
export const b14 = require('./blog/14.jpg')
export const b15 = require('./blog/15.jpg')
export const blog_1 = require('./blog/blog1.png')
export const blog_2 = require('./blog/blog2.png')
export const blog_3 = require('./blog/blog3.png')
export const blog_4 = require('./blog/blog4.png')
export const blog_5 = require('./blog/blog5.png')
export const blog_6 = require('./blog/blog6.png')
export const blog_7 = require('./blog/blog7.png')
export const hour = require('./imgs/24.png')
export const a1 = require('./about/1.png')
export const a2 = require('./about/2.png')
export const a3 = require('./about/3.png')
export const a4 = require('./about/4.png')
export const a5 = require('./about/5.png')
export const a6 = require('./about/6.png')
export const a7 = require('./about/7.png')

export const fix = require('./categories/fix.png')
export const foods = require('./categories/foods.png')
export const sweets = require('./categories/sweets.png')
export const organic = require('./categories/organic.png')
export const planets = require('./categories/planets.png')
export const animals = require('./categories/animals.png')
export const fashion = require('./categories/fashion.png')
export const gifts = require('./categories/gifts.png')
export const fregrances = require('./categories/fregrances.png')
export const paint = require('./categories/paint.png')
export const form_bg = require('./imgs/form_bg.png')
export const form_frame = require('./imgs/form_frame.png')
export const suggesst_persons = require('./imgs/suggesst_persons.png')
export const cursor = require('./imgs/cursor.png')


// ---------------------------- C A T E G O R I E S  I M A G E S------------------------------------//
export const frame = require('./categories/frame.png')
// ---- Agricultural_Products
export const fibers = require('./categories/Agricultural_Products/الالياف النباتية.png')
export const spices = require('./categories/Agricultural_Products/التوابل والاعشاب.png')
export const seeds = require('./categories/Agricultural_Products/الحبوب والبذور.png')
export const fruits = require('./categories/Agricultural_Products/الخضروات والفواكه.png')
export const plants = require('./categories/Agricultural_Products/الشتلات الزراعية.png')
export const hand = require('./categories/Agricultural_Products/المنتجات الدوائية.png')
export const feeds = require('./categories/Agricultural_Products/منتجات الاعلاف.png')
export const inplants = require('./categories/Agricultural_Products/نبات الزينة الداخلي.png')
// ---- Agricultural_Products
export const herbs = require('./categories/Organic_Products/الأعشاب العضوية.png')
export const organicFoods = require('./categories/Organic_Products/الأغذية العضوية.png')
export const hcls = require('./categories/Organic_Products/العائلة الحمضية.png')
export const honey = require('./categories/Organic_Products/العسل ومنتجات النحل.png')
export const milks = require('./categories/Organic_Products/المنتجات الألبانية.png')
export const animalsProducts = require('./categories/Organic_Products/المنتجات الحيوانية.png')
export const organics = require('./categories/Organic_Products/المنتجات العضوية.png')
export const personalCare = require('./categories/Organic_Products/مستحضرات العناية.png')
export const breeds = require('./categories/Organic_Products/منتجات الخبز.png')
// ----Sustainable_Products
export const Ecos = require('./categories/Sustainable_Products/المنتجات الصديقة للبيئة.png')
export const recycles = require('./categories/Sustainable_Products/المنتجات المعاد تدويرها.png')
// ----Sona3_Services
export const flowers = require('./categories/Sona3_Services/باقات ورد للحفلات.png')
export const bofees = require('./categories/Sona3_Services/بوفيهات المناسبات.png')
export const wrapping = require('./categories/Sona3_Services/تغليف الهدايا.png')
export const parties = require('./categories/Sona3_Services/تنسيق جلسات المناسبات.png')
export const cardsServices = require('./categories/Sona3_Services/تنفيذ بطاقات الدعوات.png')
export const givaways = require('./categories/Sona3_Services/توزيعات المناسبات.png')
export const airs = require('./categories/Sona3_Services/جلسات في الهواء الطلق.png')
export const printables = require('./categories/Sona3_Services/طلبات المطبوعات.png')
// ----Sona3_kitchen
export const globalFoods = require('./categories/Sona3_kitchen/الأطعمة العالمية.png')
export const cans = require('./categories/Sona3_kitchen/الأطعمة المعلبة.png')
export const spicies = require('./categories/Sona3_kitchen/التوابل والصلصات.png')
export const fastFoods = require('./categories/Sona3_kitchen/المأكولات الجاهزة.png')
export const drinks = require('./categories/Sona3_kitchen/المشروبات والمرطبات.png')
export const deserts = require('./categories/Sona3_kitchen/المعجنات والحلويات.png')
export const foodGifts = require('./categories/Sona3_kitchen/الهدايا الغذائية.png')
export const traditionals = require('./categories/Sona3_kitchen/مأكولات شعبية.png')


// ----Handmade_Crafts
export const Heritages = require('./categories/Handmade_Crafts/الأدوات والألات التراثية.png')
export const Antiques = require('./categories/Handmade_Crafts/التحف والأنتيكات.png')
export const woods = require('./categories/Handmade_Crafts/الحرف الخشبية.png')
export const papers = require('./categories/Handmade_Crafts/الحرف الورقية.png')
export const accessories = require('./categories/Handmade_Crafts/الحلي والأكسسوارات.png')
export const Ceramics = require('./categories/Handmade_Crafts/الخرز والأحجار الكريمة.png')
export const carpts = require('./categories/Handmade_Crafts/السجاد والسدو والنسيج.png')
export const Leather = require('./categories/Handmade_Crafts/المشغولات الجلدية.png')
export const coppers = require('./categories/Handmade_Crafts/المشغولات النحاسية.png')
export const HeritageClothes = require('./categories/Handmade_Crafts/الملابس التراثية.png')
export const soaps = require('./categories/Handmade_Crafts/صناعة الصابون والشموع.png')
export const strings = require('./categories/Handmade_Crafts/فن الخيوط.png')
// ----Artistic_Artifacts
export const graphics = require('./categories/Artistic_Artifacts/الجرافيك التصويري.png')
export const glosses = require('./categories/Artistic_Artifacts/الحرف الزجاجية.png')
export const digitalPainting = require('./categories/Artistic_Artifacts/الرسم الرقمي.png')
export const carokeePainting = require('./categories/Artistic_Artifacts/الرسم الكاريكاتير.png')
export const sandPainting = require('./categories/Artistic_Artifacts/الرسم بالرمل.png')
export const coalPaint = require('./categories/Artistic_Artifacts/الرسم بالفحم.png')
export const leathersPainting = require('./categories/Artistic_Artifacts/الرسم علي الأقمشة.png')
export const abstractPainting = require('./categories/Artistic_Artifacts/الفنون التجريدية.png')
export const SculpturePainting = require('./categories/Artistic_Artifacts/النحت والنقش.png')
export const fonting = require('./categories/Artistic_Artifacts/فن الخط العربي.png')
export const artistic = require('./categories/Artistic_Artifacts/لوحات فنية.png')
export const mosaic = require('./categories/Artistic_Artifacts/فن الفسيفساء.png')

// ----Sona3_Atyab
export const Ouds = require('./categories/Sona3_Atyab/البخور والعود.png')
export const Marine = require('./categories/Sona3_Atyab/العائلة البحرية.png')
export const leatherFamily = require('./categories/Sona3_Atyab/العائلة الجلدية.png')
export const modernFamily = require('./categories/Sona3_Atyab/العائلة الحديثة.png')
export const sweetFamily = require('./categories/Sona3_Atyab/العائلة الحلوة.png')
export const citrus = require('./categories/Sona3_Atyab/العائلة الحمضية.png')
export const woodenFamily = require('./categories/Sona3_Atyab/العائلة الخشبية.png')
export const greenFamily = require('./categories/Sona3_Atyab/العائلة الخضراء.png')
export const flowerFamily = require('./categories/Sona3_Atyab/العائلة الزهرية.png')
export const eastFamily = require('./categories/Sona3_Atyab/العائلة الشرقية.png')
export const herbsFamliy = require('./categories/Sona3_Atyab/العائلة العشبية.png')
export const fruitsFamily = require('./categories/Sona3_Atyab/العائلة الفاكهة.png')



export const qr_apk_seller = require('./qr_codes/seller_android_qr.png');
export const qr_android_customer = require('./qr_codes/customer_android_qr.jpeg');


export const partner_1 = require('./partners/1.png');
export const partner_2 = require('./partners/2.png');
export const partner_3 = require('./partners/3.png');
export const partner_4 = require('./partners/4.png');
export const partner_5 = require('./partners/5.png');
export const partner_6 = require('./partners/6.png');
export const partner_7 = require('./partners/7.png');

export const pdf_icon = require('./icons/pdf.png');
export const group_icon = require('./icons/group_icon.png');
export const medal_icon = require('./icons/medal_icon.png');

export const ss_cover = require('./imgs/ss_cover.png');
export const apple = require('./icons/apple.png');
export const play = require('./icons/play.png');
export const apk = require('./qr_codes/apk.png');
export const AndroidCustomer = require('./qr_codes/sona3ANDROIDCustomer.png');
export const AndroidSeller = require('./qr_codes/sona3ANDROIDSeller.png');
export const ios = require('./qr_codes/ios.png');
export const IOSCustomer = require('./qr_codes/sona3IOSCustomer.png');
export const IOSSeller = require('./qr_codes/sona3IOSSeller.png');
export const soon = require('./icons/soon.png');


export const sustainabilty_image = require('./imgs/Sustainability-footer.png');


export const error_404 = require('./imgs/error-404.png');




// ---------------------------- S U S T A I N A B I L I T Y  I M A G E S------------------------------------//
export const sustainabilty_logo = require('./sustainabilty/sustainability_logo.png');
export const i_1 = require('./sustainabilty/icon_1.png');
export const i_2 = require('./sustainabilty/icon_2.png');
export const i_3 = require('./sustainabilty/icon_3.png');
export const img_1 = require('./sustainabilty/img_1.jpeg');
export const img_2 = require('./sustainabilty/img_2.jpeg');
export const img_3 = require('./sustainabilty/img_3.jpeg');
export const img_4 = require('./sustainabilty/img_4.jpeg');
export const img_5 = require('./sustainabilty/img_5.jpeg');
export const corner = require('./sustainabilty/corner.png');
export const corner2 = require('./sustainabilty/corner2.png');
export const corner3 = require('./sustainabilty/corner3.png');

// ---------------------------- P R I C I N G  I M A G E S------------------------------------//
export const pricing_cover = require('./pricing/cover.png');
export const pricing_cover_en = require('./pricing/cover_en.png');
export const arrow_vector = require('./pricing/vector.png');
export const bg = require('./pricing/bg.png');
export const map = require('./pricing/map.png');
export const map_en = require('./pricing/map_en.png');
export const content = require('./pricing/content.png');
export const content_en = require('./pricing/content_en.png');
export const wave_up = require('./pricing/wave_up.png');
export const wave_down = require('./pricing/wave_down.png');
export const pricing_1 = require('./pricing/icon_1.png');
export const pricing_2 = require('./pricing/icon_2.png');
export const pricing_3 = require('./pricing/icon_3.png');
export const pricing_4 = require('./pricing/icon_4.png');
export const pricing_5 = require('./pricing/icon_5.png');
export const pricing_6 = require('./pricing/icon_6.png');
export const pricing_7 = require('./pricing/icon_7.png');
export const pricing_8 = require('./pricing/icon_8.png');
export const pricing_9 = require('./pricing/icon_9.png');



// ----------------------------A B O U T  I M A G E S------------------------------------//

export const about_line = require('./about/line.png');
export const about_cover = require('./about/bg.png');
export const about_cover_flip = require('./about/bg_flip.png');
export const about_eye = require('./about/eye.png');
export const about_target = require('./about/target.png');
export const glamour = require('./about/glamour.png');
export const grid = require('./about/grid.png');
export const about_payment = require('./about/payment.png');
export const about_bottles = require('./about/bottels.png');
export const about_globals = require('./about/globals.png');
export const about_stas = require('./about/stats.png');
export const about_alert = require('./about/alert.png');
export const about_notes = require('./about/note.png');
export const about_lang = require('./about/language.png');
export const about_lock= require('./about/lock.png');
export const about_cs= require('./about/cs.png');
export const about_gps= require('./about/location.png');
export const about_wallet= require('./about/wallet.png');
export const about_browse= require('./about/browse.png');
export const about_shipping= require('./about/shipping.png');
export const about_compare= require('./about/compare.png');
export const about_pointer= require('./about/pointer.png');



